@use '@angular/material' as mat;
@use 'sass:map';
@include mat.core();

$_palettes: (
  primary: (
    0: #000000,
    10: #0c1b38,
    20: #22304e,
    25: #2e3b5a,
    30: #394666,
    35: #455272,
    40: #515e7f,
    50: #697799,
    60: #8390b3,
    70: #9dabcf,
    80: #b9c6ec,
    90: #d9e2ff,
    95: #edf0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #0c1b38,
    20: #22304e,
    25: #2e3b5a,
    30: #394666,
    35: #455272,
    40: #515e7f,
    50: #697799,
    60: #8390b3,
    70: #9dabcf,
    80: #b9c6ec,
    90: #d9e2ff,
    95: #edf0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1b1b1e,
    20: #303033,
    25: #3b3b3e,
    30: #474649,
    35: #525255,
    40: #5e5e61,
    50: #777779,
    60: #919093,
    70: #acabad,
    80: #c8c6c9,
    90: #e4e2e5,
    95: #f2f0f3,
    98: #fbf8fb,
    99: #fefbfe,
    100: #ffffff,
    4: #0e0e10,
    6: #131315,
    12: #1f1f22,
    17: #2a2a2c,
    22: #343437,
    24: #39393b,
    87: #dbd9dc,
    92: #eae7ea,
    94: #efedf0,
    96: #f5f3f6,
  ),
  neutral-variant: (
    0: #000000,
    10: #1f2228,
    20: #33373e,
    25: #44484e,
    30: #50555e,
    35: #56595e,
    40: #62677e,
    50: #747990,
    60: #8f939e,
    70: #a6a8b3,
    80: #c7c8ce,
    90: #e5e5eb,
    95: #f3f3f9,
    98: #fbfbff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary
  ),
  density: (
    scale: 0,
  )
));
/* $dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));
 */

// define theme

/* $theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    //#ff5e38,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));
 */

 //for more use ng generate @angular/material:m3-theme
:root {

  @include mat.all-component-themes($light-theme);
  @include mat.divider-overrides((
    color: rgb(209, 209, 209),
  ));

  // progress spinnner
  .mat-mdc-progress-spinner.mat-accent {
    --mdc-circular-progress-active-indicator-color: var(--warmOrange);
    /* transform: translate(0%, 50%); */
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // Slide toggle
  .mdc-switch--selected .mdc-switch__icon{
    --mdc-switch-selected-icon-color: var(--warmOrange);
  }

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-handle-color: #f2f2f2;
    --mdc-switch-selected-hover-state-layer-color: var(--warmOrange);
    --mdc-switch-selected-focus-handle-color: var(--warmOrange);
    --mdc-switch-selected-hover-handle-color: #ffffff;
    --mdc-switch-selected-pressed-handle-color: rgb(255, 209, 199);
    --mdc-switch-selected-focus-track-color: #f2f2f2;
    --mdc-switch-selected-hover-track-color: rgb(255, 209, 199);
    --mdc-switch-selected-pressed-track-color: #fff;
    --mdc-switch-selected-track-color: var(--warmOrange);
  } 
  
  .mat-mdc-slide-toggle {
    --mdc-switch-unselected-hover-state-layer-color: #ffcfaa;
    --mdc-switch-unselected-focus-track-color: #e0e0e0;
    --mdc-switch-unselected-hover-track-color: #e0e0e0;
    --mdc-switch-unselected-pressed-track-color: #e0e0e0;
    --mdc-switch-unselected-track-color: #e0e0e0;
    --mdc-switch-unselected-focus-handle-color: var(--warmOrange);
    --mdc-switch-unselected-hover-handle-color: var(--warmOrange);
    --mdc-switch-unselected-pressed-handle-color: var(--warmOrange);
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-unselected-handle-color: #616161;
    --mdc-switch-disabled-selected-icon-color: #fff;
    --mdc-switch-disabled-unselected-icon-color: #fff;
    --mdc-switch-unselected-icon-color: #fff;
  } 
  
  /* snackBar */
  .mat-mdc-snack-bar-container {
    &.snackBarStyle {
      --mdc-snackbar-container-color: var(--darkBlue);
      --mat-mdc-snack-bar-button-color: var(--warmOrange);
    }
  }

  --mat-expansion-actions-divider-color: #d4d4d4;
  --mdc-elevated-card-container-color: #fff;
  --mat-table-background-color: #fff;
  --mat-paginator-container-background-color: #fff;
  --mdc-dialog-container-color: #fff;
  --mat-expansion-container-background-color: #fff;
  --mat-menu-container-color: #fff;  

  app-cd-settings, app-media, app-media-folder-content, app-pass-templates, app-pass-templates-translations{
    --mat-standard-button-toggle-divider-color:#fff
  }

  app-navbar-menu{
    --mdc-list-list-item-one-line-container-height: 44px;
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel:has(app-text-translation-edit, app-annotated-map-annotation-translation-dialog, app-annotated-map-translation-edit, app-buttons-translation-edit, app-image-translation-edit) {
    max-width: unset;
  }

  .search {
    --mdc-filled-text-field-container-color: transparent;
  }

  h1{
    font-size: 2.2rem;
  }
  h2{
    font-size: 1.4rem;
  }
  h3{
    font-size: 1.2rem;
  }
  h4{
    font-size: 1rem;
  }

  // fix toggle spacing
  .form-control.d-flex.justify-content-between label{
    align-self: center;
  }

}
